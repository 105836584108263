<template>
  <!-- <h1 class="pb-4">
    <p>
      <strong>YOU'RE DONE</strong>
    </p>
    <p>
      YOU HAVE SUCCESSFULLY ANSWERED:
    </p>
  </h1> -->
  <div class="col-12 p-0 mb-4 bigger-text">
      YOU'RE DONE
    </div>
    <div class="col-12 p-0 mb-1 smaller-text">
      YOU HAVE SUCCESSFULLY ANSWERED:
    </div>
  <div class="row mx-0 position-relative align-items-center justify-content-center">
    <div class="d-flex justify-content-center align-items-center rating">
      <span class="number">{{ correct }}</span>
      <span class="text mx-2">OUT OF</span>
      <span class="number">{{ numberQuestion }}</span>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-3">
      <h2>
        You managed that in <span style="color: #000">{{ time }}</span>
      </h2>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-12 p-0">
        <button @click="leaderboard" class="arrow-button">
          <span class="arrow-icon">&gt;</span>
        </button>
      </div>
    </div>
    <!-- <div class="col-12 p-0 mb-5 text text-center text-lg-start d-flex justify-content-center">
      <div>{{ correct }}</div> OUT OF {{ numberQuestion }}
      QUESTIONS CORRECT IN {{ time }}
    </div> -->
    <!-- <div class="col-auto p-0 pt-4">
      <Button @clicked="leaderboard">
        VIEW LEADERBOARD
      </Button>
    </div> -->
  </div>
</template>

<script>
// import Button from '../components/Button.vue'
export default {
  // components: { Button },
  name: 'QuizResult',
  props: ['correct', 'numberQuestion', 'time', 'id'],
  methods: {
    leaderboard () {
      this.$router.push(`/leaderboard/${this.id}`)
    }
  }
}
</script>

<style scoped>
.rating {
  font-weight: bold; /* If you want the font to be bold */
}

.rating .number {
  font-size: 7.5rem; /* Adjust size as needed */
}

.rating .text {
  font-size: 3.5rem; /* Adjust size as needed */
  vertical-align: super; /* Adjust alignment if necessary */
}
.arrow-button {
  border: none;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #d3202f;
  font-size: 24px; /* Change size as needed */
}
.smaller-text {
  font-size: 2.2rem;
  text-transform: uppercase;
}
.bigger-text {
  font-size: 3rem;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .text {
    font-size: 2.25rem;
  }
}
</style>
