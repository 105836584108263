<template>
  <h1 class="pb-4">
    QUESTIONS
  </h1>
  <div class="row mx-0">
    <div class="col-12 p-0 question mb-5">
      {{ question.question }}
    </div>
    <div class="col-12 p-0">
      <div class="row m-0">
        <div class="col-6" v-for="answer in question.answers" :key="answer.id">
          <button
            type="button"
            class="btn-checkbox"
            :class=addAnsweredClass(answer)
            @click="selectAnswer(answer)">
              {{ answer.answer }}
          </button>
        </div>
        <!-- <div class="col-12 mb-4 mb-lg-5 medium p-0" v-for="(answer, key) in question.answers" :key="key">
          <label class="container_checkbox">
            <input v-model="theAnswer" :disabled="theAnswer !== null" type="radio" class="form-check-input" name="question" :value="answer.id">
            <span class="checkmark" />
            <div class="form_label" :class="addAnsweredClass(answer)">{{ answer.answer }}</div>
          </label>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Questions',
  props: ['question', 'currentQuestionAnswer'],
  emits: ['update:currentQuestionAnswer'],
  data () {
    return {
      theAnswer: null
    }
  },
  watch: {
    question: {
      handler () {
        this.theAnswer = null
      },
      deep: true
    },
    theAnswer (newVal) {
      this.$emit('update:currentQuestionAnswer', newVal)
    }
  },
  methods: {
    addAnsweredClass (answer) {
      if ((this.theAnswer === answer.id && answer.correct) || (this.theAnswer && answer.correct)) {
        return 'text-success'
      } else if (this.theAnswer === answer.id && !answer.correct) {
        return 'text-warning'
      }
      return ''
    },
    selectAnswer (answer) {
      this.theAnswer = !this.theAnswer ? answer.id : this.theAnswer
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-checkbox {
  display: block;
  width: 100%;
  background-color: white;
  color: red;
  text-align: center;
  padding: 10px;
  border: 2px solid grey;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
}

.btn-checkbox:hover {
  background-color: #f8f9fa;
}

/* Additional classes for color changes */
.text-success {
  color: green !important;
}

.text-warning {
  color: amber !important;
}
.text-warning {
  color: amber !important;
}
.question {
  color: #fff;
  font-size: 1.75rem;
  text-transform: uppercase;
}
.container_checkbox {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* width: fit-content; */
}
.container_checkbox .form_label {
  padding-left: 2.5rem;
  min-height: 24px;
  font-size: 1.3rem;
}
/* Hide the browser's default radio button */
.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  border: 2px solid rgb(252, 181, 51);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: transparent;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container_checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container_checkbox .checkmark:after {
  left: -2px;
  top: -2px;
  width: 24px;
  height: 24px;
  border: 6px solid rgb(252, 181, 51);
  background-color: #fff;
  border-radius: 50%;
}
.correct {
  color: #62b95d;
}
.incorrect {
  color: #ef435d;
}
/* Show the indicator (dot/circle) when checked */
@media (min-width: 992px) {
  .question {
    font-size: 1.75rem;
  }
  .container_checkbox .form_label {
    padding-left: 4rem;
    font-size: 2rem;
  }
  .checkmark {
    height: 34px;
    width: 34px;
    top: 5px;
    border: 2px solid rgb(252, 181, 51);
  }
  .container_checkbox .checkmark:after {
    left: -2px;
    top: -2px;
    width: 34px;
    height: 34px;
    border: 10px solid rgb(252, 181, 51);
    background-color: #fff;
    border-radius: 50%;
  }
}
</style>
