<template>
  <div class="fullscreen-background" :class="!showResult ? 'question-background' : 'result-background'"></div>
  <img src="../assets/images/logo_avis.png" class="logo" >
  <div v-if="!showResult">
    <!-- <div class="position-absolute top-50 translate-middle-y d-none d-lg-block">
      <clock :time=timer />
    </div> -->
    <div class="row mx-0 position-relative align-items-center">
      <div class="col-12 p-0 col-lg-10 mb-5">
        <questions
          v-if="questions.length > 0"
          :question="questions[questionNum]"
          v-model:currentQuestionAnswer=currentQuestionAnswer
        />
      </div>
      <div class="row mx-0 mb-0">
        <div class="col-12 p-0">
          <button @click="next" class="arrow-button">
            <span class="arrow-icon">&gt;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <QuizResult v-else
    :correct="correctAnswers"
    :numberQuestion="numberQuestion"
    :time="timer"
    :id="id"
  />
</template>

<script>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
// import Button from '../components/Button.vue'
// import Clock from '../components/Clock.vue'
import Questions from '../components/Questions.vue'
import QuizResult from './QuizResult.vue'
import { getQuestions, postAnswers } from '../api'
import { useStore } from 'vuex'

export default {
  components: {
    Questions,
    QuizResult
  },
  name: 'Quiz',
  setup () {
    const route = useRoute()
    const store = useStore()

    const id = ref(route.params.id)
    const showResult = ref(false)
    const questionNum = ref(0)
    const timer = ref('00:00')
    const time = ref(0)
    const individualQuestionTime = ref(0)
    const isRunning = ref(false)
    const currentQuestionAnswer = ref(null)
    const participantAnswers = ref([])
    const correctAnswers = ref(0)
    const numberQuestion = ref(5)
    return {
      id,
      showResult,
      questions: computed(() => store.state.questions),
      questionNum,
      timer,
      isRunning,
      time,
      individualQuestionTime,
      currentQuestionAnswer,
      participantAnswers,
      correctAnswers,
      numberQuestion
    }
  },
  watch: {
    currentQuestionAnswer (newVal) {
      this.toggleTimer()
    }
  },
  async mounted () {
    if (this.$store.getters.questions.length === 0) {
      const loader = this.$loading.show()
      try {
        const response = await getQuestions(this.$route.params.id)
        // console.log('response', response)
        if (response.data.status) {
          this.$store.dispatch('setUserQuestions', response.data.data)
          this.numberQuestion = response.data.data.length
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data)
        } else {
          alert(error.message)
        }
      }
      loader.hide()
    }
    this.toggleTimer()
  },
  methods: {
    toggleTimer () {
      if (this.isRunning) {
        clearInterval(this.interval)
        // console.log('timer stops')
      } else {
        this.interval = setInterval(this.incrementTime, 1000)
      }
      this.isRunning = !this.isRunning
    },
    incrementTime () {
      this.time = parseInt(this.time) + 1
      this.individualQuestionTime = parseInt(this.individualQuestionTime) + 1
      const min = Math.floor(this.time / 60)
      const sec = this.time % 60
      this.timer = (min < 10 ? '0' + min : min) + ':' + (sec < 10 ? '0' + sec : sec)
    },
    async next () {
      if (this.currentQuestionAnswer) {
        this.addAnswer()
        if (this.questionNum < this.questions.length - 1) {
          this.questionNum++
          this.individualQuestionTime = 0
        } else {
          // this.toggleTimer()
          const loader = this.$loading.show()
          const response = await postAnswers({
            id: this.$route.params.id,
            answers: this.participantAnswers
          })
          loader.hide()
          this.correctAnswers = response.data.data.correct_answers
          this.showResult = true
        }
      }
    },
    addAnswer () {
      const question = this.participantAnswers.find(a => a.questionId === this.questions[this.questionNum].id)
      if (!question) {
        this.participantAnswers.push({
          questionId: this.questions[this.questionNum].id,
          answerId: this.currentQuestionAnswer,
          time: this.individualQuestionTime
        })
      } else {
        question.answerId = this.currentQuestionAnswer
        question.time = this.individualQuestionTime
      }
    },
    leaderboard () {
      this.$router.push(`/leaderboard/${this.$route.params.id}`)
    }
  }
}
</script>

<style scoped>
.absolute_bottom {
  position: fixed;
  bottom: 0.5rem;
  text-align: center;
}
.position-absolute {
  right: 5rem;
  z-index: 3;
}

.arrow-button {
  border: none;
  background-color: #d3202f;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #fff;
  font-size: 24px; /* Change size as needed */
}
.fullscreen-background {
  position: fixed; /* or 'absolute' if you want it to scroll with the page */
  top: 0;
  left: 0;
  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  background-size: cover; /* cover the entire viewport */
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* send it to the back */
}
.question-background {
  background-image: url('../assets/images/bg_quiz.png');
}

.result-background {
  background-image: url('../assets/images/bg_quiz_result.png');
}

@media (min-width: 992px) {
  .absolute_bottom {
    bottom: 2rem;
  }
}
</style>
